import React from "react"
import About from "../components/about"
import SEO from "../components/seo"
import ChallengeSuccessHero from "../components/challenge/success_hero"
import ChallengeSuccessMessage from "../components/challenge/success_message"
import ChallengePages from "../components/challenge/pages"
import ChallengeFooter from "../components/challenge/footer"

const ChallengeSuccess = () => (
  <div className="awc-challenge">
    <SEO title="Webinar confirmation" />
    <ChallengeSuccessHero />
    <ChallengeSuccessMessage />
    <About />
    <ChallengePages />
    <ChallengeFooter />
  </div>
)

export default ChallengeSuccess
