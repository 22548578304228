import React from "react"
import pagesImage from "../../assets/images/pages.png"

function ChallengePages() {
  return (
    <section className="challenge-pages-section">
      <div className="container">
        <h2>Create A Stunning Website For Your Business</h2>
        <h3>
          All you need in one place to create, design and launch your website
          exactly the way you want
        </h3>
        <img src={pagesImage} />
      </div>
    </section>
  )
}

export default ChallengePages
