import React from "react"
import config from "../../../config/website"
import arrowDownImage from "../../assets/images/arrow-down.png"

function ChallengeSuccessMessage() {
  return (
    <div className="challenge-message-section">
      <div className="container">
        <h3>Watch this video for a special message from Tara</h3>
        <img src={arrowDownImage} width="100" alt="" />

        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/fVIQgK3qRwg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <p>
          PS- If you already have a website software that you like… you still
          need to sign up for this 14-day trial, but you can cancel anytime.
          This is my favorite website-building tool so I want to show it to you
          because I think you’ll love it!
        </p>
        <a href={`${config.appUrl}/subscription`} className="cta">
          Start Your Free Trial Today
        </a>
      </div>
    </div>
  )
}

export default ChallengeSuccessMessage
