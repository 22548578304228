import React from "react"
import config from "../../../config/website"

function ChallengeSuccessHero() {
  return (
    <div className="challenge-success-section">
      <div className="container">
        <h2>YOU ARE IN! THE FINAL STEP IS TO...</h2>
        <h3>
          Start your free trial of my favorite website builder, the one we will
          use during the challenge!
        </h3>
        <a href={`${config.appUrl}/subscription`} className="cta">
          Start Your Free Trial Today
        </a>
        <p>Try Way. Start your free trial today. Cancel anytime.</p>
      </div>
    </div>
  )
}

export default ChallengeSuccessHero
